import './main.scss'

document.addEventListener('DOMContentLoaded', () => {
  // Toogle admin bar open when the main button is clicked
  document.querySelectorAll('.simplero-admin-bar-button').forEach((el) => {
    el.addEventListener('click', (e) => {
      e.stopPropagation()
      e.stopImmediatePropagation()
      e.target
        .closest('.simplero-admin-bar')
        .classList.toggle('simplero-admin-bar-open')
    })
  })

  // Close admin bar when clicking any link inside it
  document.querySelectorAll('.simplero-admin-bar-menu a').forEach((el) => {
    el.addEventListener('click', (e) => {
      e.target
        .closest('.simplero-admin-bar')
        .classList.remove('simplero-admin-bar-open')
    })
  })

  // Clicking anywhere but the admin bar closes it
  if (document.querySelector('.simplero-admin-bar')) {
    document.addEventListener('click', () => {
      document.querySelectorAll('.simplero-admin-bar').forEach((el) => {
        el.classList.remove('simplero-admin-bar-open')
      })
    })
  }
})
